import React from 'react'
import { Nav, Navbar, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from "react-router-dom";

export default () => {
  function handleSelection( item){
    switch(item) {
      case "#choose-date":
        // code block
        // dispatch(openActiveDateSelector());
        break;
      case "":
        // code block
        break;
      default:
        // code block
    }
  }

  return (
    // <div>
    //     <Navbar fluid collapseOnSelect>
    //     <Navbar.Header>
    //       <Navbar.Brand>
    //         <Link to="/">Scratch</Link>
    //       </Navbar.Brand>
    //       <Navbar.Toggle />
    //     </Navbar.Header>
    //             {/* <Navbar.Header> */}
    //       {/* <Navbar.Brand> */}
    //         {/* <Link to="/">Scratch</Link> */}
    //       {/* </Navbar.Brand> */}
    //     {/* </Navbar.Header> */}
    //     {/* <Navbar.Collapse> */}
    //       {/* <Nav pullRight> */}
    //         {/* <LinkContainer to="/signup"> */}
    //           {/* <NavItem>Signup</NavItem> */}
    //         {/* </LinkContainer> */}
    //         {/* <LinkContainer to="/login"> */}
    //           {/* <NavItem>Login</NavItem> */}
    //         {/* </LinkContainer> */}
    //       {/* </Nav> */}
    //     {/* </Navbar.Collapse> */}
    //   </Navbar>
    // </div>
    <div>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" onSelect={(item) => handleSelection( item)}>
        <Navbar.Brand href="/">          
          {/* <img alt="" src="/logo_vy.png" width="48" className="d-inline-block align-top" /> */}
          {' LavkarBu'}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <li className="nav-item">
                <Link className = "nav-link" to="/weather">Vær</Link>
              </li>
              <li className="nav-item">
                <Link className = "nav-link" to="/image">Bilde</Link>
              </li>
              <li className="nav-item">
                <Link className = "nav-link" to="/memory">Good times</Link>
              </li>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
    </div>

  )
}
