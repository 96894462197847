import React from 'react'

export default () => {
  return (
    <div>
        <h1 className="display-4 text-danger">Good times!!</h1>
      <img src="lav/reslist1984.png" width="100%"/>
    </div>
  )
}
