import React from 'react'

export default () => {
  return (
    <div>
        <h1 className="display-4 text-danger">Hipp hipp Arve!!</h1>
      <img src="lav/giphy.gif" width="100%"/>
    </div>
  )
}
