import React, { Component } from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom'
import Menu from './layout/Menu'
import Image from './pages/Image'
import Weather from './pages/Weather'
import Memory from './pages/Memory'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Menu></Menu>
          <div className="container">
          <Switch>
              <Route exact path="/" component={Weather}/>
              <Route path="/weather" component={Weather}/>
              <Route path="/image" component={Image}/>
              <Route path="/memory" component={Memory}/>
          </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
