import React from 'react'

export default () => {
  return (
    <div>
        <h1 className="display-4 text-danger">Dagens</h1>
        <img src="lav/image.jpeg" width="100%"/>
    </div>
  )
}
